import { lightTheme } from '@leadrilla/pulsar'
// import { COLORS } from '@leadrilla/pulsar/dist/components/GlobalStyles/constants'

// TODO - figure out how to import COLORS from pulsar
// TODO - we really should remove the themes (and COLORS) from pulsar and define them in the client.
// TODO - Also should update pulsar so that it can generate the subtle, light, dark, etc. color variations from a single color. We should be able to override the specific color variations, but Pulsar should be able to work by providing just a single primary color and a single secondary color. Review how MUI handles theming.

export const COLORS = {
  valhalla: {
    subtle: '#DCE4F7',
    light: '#B9C9EF',
    standard: '#4F77D8',
    hover: '#5B8AFF',
  },
  bethpage: {
    subtle: '#CFCFD1',
    light: '#86888B',
    standard: '#26292F',
    dark: '#0E1118',
  },
  whistling: {
    light: '#F7F8FA',
    standard: '#EFF1F5',
  },
  pebble: '#FAFAFC',
  ghost: '#FFFFFF',
  augusta: {
    subtle: '#E1EFE4',
    light: '#C3DEC9',
    standard: '#69AD78',
    dark: '#609D6E',
  },
  merion: {
    subtle: '#F7DDDD',
    light: '#F0BBBB',
    standard: '#D95656',
    dark: '#C44F50',
    hover: '#FB5B5B',
  },
  dune: {
    subtle: '#FDF1DF',
    light: '#F9DBAC',
    standard: '#F4BA61',
    dark: '#C08A00',
  },
  teal: {
    standard: '#3EA590',
    ten: '#ECF7F4',
    thirty: '#C5E4DD',
    sixty: '#8CCABD',
    hover: '#377E67',
  },
  coolGray: {
    ten: '#FAFAFB',
    thirty: '#EDEFF0',
    sixty: '#DCE0E3',
    standard: '#C5CCD0',
  },
  blue: {
    ten: '#EAEDF0',
    thirty: '#BDC6CF',
    sixty: '#7C8EA0',
    standard: '#244260',
  },
  mediumBlue: {
    ten: '#E6EEF2',
    thirty: '#B2C9D7',
    sixty: '#6695B0',
    standard: '#244260',
  },
  green: {
    standard: '#72C596',
    sixty: '#AADCC0',
    thirty: '#D4EDDF',
    ten: '#F1FAF5',
  },
  leadstarPurple: {
    standard: '#7F287F',
    sixty: '#B27EB2',
    thirty: '#D9BFD9',
    ten: '#F2E9F2',
    hover: '#AE37AE',
  },
  leadstarBlack: {
    standard: '#353535',
    sixty: '#868686',
    thirty: '#C2C2C2',
    ten: '#EBEBEB',
  },
} as const

export const demoTheme = {
  ...lightTheme,
  brand: {
    ...lightTheme.brand,
    action: '#3E3EF9',
    actionLight: '#C5C5FD',
    actionSubtle: '#ECECFE',
    iconBase: '#1A1A1A',
    iconAccent: '#3E3EF9',
  },
  button: {
    ...lightTheme.button,
    text: {
      ...lightTheme.button.text,
      secondary: '#3E3EF9',
      link: '#3E3EF9',
    },
    bg: {
      ...lightTheme.button.bg,
      primary: '#3E3EF9',
    },
    hover: {
      ...lightTheme.button.hover,
      primary: '#0707df',
      secondary: '#e6e6fe',
      link: '#0707df',
    },
    border: {
      ...lightTheme.button.border,
      primary: '#3E3EF9',
      secondary: '#3E3EF9',
    },
  },
  'choice-chip': {
    text: {
      ...lightTheme['choice-chip'].text,
      active: '#3E3EF9',
    },
    hover: {
      ...lightTheme['choice-chip'].hover,
      default: '#3E3EF9',
    },
    bg: {
      ...lightTheme['choice-chip'].bg,
      active: '#ECECFE',
    },
    border: {
      ...lightTheme['choice-chip'].border,
      active: '#ECECFE',
    },
  },
}

const edmActionColor = '#618E7B'
export const edmTheme = {
  ...lightTheme,
  brand: {
    ...lightTheme.brand,
    action: edmActionColor,
    actionLight: '#D0DDD7',
    actionSubtle: '#EFF4F2',
    iconBase: '#000000',
    iconAccent: edmActionColor,
  },
  button: {
    ...lightTheme.button,
    text: {
      ...lightTheme.button.text,
      secondary: edmActionColor,
      link: edmActionColor,
    },
    bg: {
      primary: edmActionColor,
      secondary: 'transparent',
      destructive: edmActionColor,
    },
    hover: {
      primary: '#94E4C3',
      secondary: '#EFF4F2',
      destructive: edmActionColor,
      link: '#EFF4F2',
    },
    border: {
      primary: edmActionColor,
      secondary: edmActionColor,
      destructive: edmActionColor,
      link: 'transparent',
    },
  },
  'choice-chip': {
    text: {
      default: COLORS.bethpage.light,
      active: edmActionColor,
      disabled: COLORS.bethpage.subtle,
    },
    hover: {
      default: edmActionColor,
    },
    bg: {
      default: COLORS.whistling.standard,
      active: '#EFF4F2',
      disabled: COLORS.pebble,
    },
    border: {
      default: COLORS.whistling.standard,
      active: '#EFF4F2',
      disabled: COLORS.pebble,
    },
  },
}

const trkingActionColor = '#13AAC9'
export const trkingTheme = {
  ...lightTheme,
  brand: {
    ...lightTheme.brand,
    action: trkingActionColor,
    actionLight: '#B8E6EF',
    actionSubtle: '#E7F7FA',
    iconBase: '#1A1A1A',
    iconAccent: '#009999',
  },
  button: {
    ...lightTheme.button,
    text: {
      ...lightTheme.button.text,
      secondary: trkingActionColor,
      link: trkingActionColor,
    },
    bg: {
      primary: trkingActionColor,
      secondary: 'transparent',
      destructive: trkingActionColor,
    },
    hover: {
      primary: '#23A3D9',
      secondary: '#EFF4F2',
      destructive: trkingActionColor,
      link: '#EFF4F2',
    },
    border: {
      primary: trkingActionColor,
      secondary: trkingActionColor,
      destructive: trkingActionColor,
      link: 'transparent',
    },
  },
  'choice-chip': {
    text: {
      default: COLORS.bethpage.light,
      active: trkingActionColor,
      disabled: COLORS.bethpage.subtle,
    },
    hover: {
      default: trkingActionColor,
    },
    bg: {
      default: COLORS.whistling.standard,
      active: '#EFF4F2',
      disabled: COLORS.pebble,
    },
    border: {
      default: COLORS.whistling.standard,
      active: '#EFF4F2',
      disabled: COLORS.pebble,
    },
  },
}

const createTheme = (clientColors: {
  actionStandard: string // valhalla standard
  actionHover: string // valhalla hover
  actionLight: string // valhalla light
  actionSubtle: string // valhalla subtle
  neutral: string // bethpage/bethpage dark
  dark?: string // bethpage dark (neutral is used if this is omitted)
  light: string // bethpage light
  subtle: string // bethpage subtle
  background: string // whistling
  highlight: string // whistling light
  contrast: string // pebble
  foreground: string // ghost
}) => {
  const darkColor = clientColors.dark || clientColors.neutral
  return {
    ...lightTheme,
    brand: {
      ...lightTheme.brand,
      action: clientColors.actionStandard,
      actionLight: clientColors.actionLight,
      actionSubtle: clientColors.actionSubtle,
      iconBase: clientColors.neutral,
      iconAccent: clientColors.actionStandard,
      background: clientColors.background,
      foreground: clientColors.foreground,
      modalBackdrop: darkColor,
      hairline: clientColors.background,
      highlight: clientColors.highlight,
      neutral: clientColors.neutral,
      subtle: clientColors.subtle,
      light: clientColors.light,
      info: clientColors.actionStandard,
      infoSubtle: clientColors.actionSubtle,
      infoLight: clientColors.actionLight,
      contrast: clientColors.contrast,
    },
    button: {
      ...lightTheme.button,
      text: {
        ...lightTheme.button.text,
        primary: clientColors.foreground,
        secondary: clientColors.actionStandard,
        destructive: clientColors.foreground,
        link: clientColors.actionStandard,
      },
      bg: {
        ...lightTheme.button.bg,
        primary: clientColors.actionStandard,
      },
      hover: {
        ...lightTheme.button.hover,
        primary: clientColors.actionHover,
        secondary: clientColors.actionSubtle,
        link: clientColors.actionSubtle,
      },
      border: {
        ...lightTheme.button.border,
        primary: clientColors.actionStandard,
        secondary: clientColors.actionStandard,
      },
    },
    'choice-chip': {
      text: {
        default: clientColors.light,
        active: clientColors.actionStandard,
        disabled: clientColors.subtle,
      },
      hover: {
        default: clientColors.actionStandard,
      },
      bg: {
        default: clientColors.background,
        active: clientColors.actionSubtle,
        disabled: clientColors.contrast,
      },
      border: {
        default: clientColors.background,
        active: clientColors.actionSubtle,
        disabled: clientColors.contrast,
      },
    },
    'input-chip': {
      bg: {
        ...lightTheme['input-chip'].bg,
        neutral: clientColors.actionSubtle,
      },
    },
    textfield: {
      ...lightTheme.textfield,
      bg: {
        ...lightTheme.textfield.bg,
        disabled: clientColors.highlight,
      },
      border: {
        ...lightTheme.textfield.border,
        neutral: clientColors.subtle,
      },
    },
    font: {
      name: 'Assistant',
      url: 'https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700;800&display=swap',
    },
  }
}

export const advocateColors = {
  actionStandard: '#419444', // valhalla standard
  actionHover: '#60DA64', // valhalla hover
  actionLight: '#C6DFC7', // valhalla light
  actionSubtle: '#ECF4EC', // valhalla subtle
  neutral: '#1A2D1A', // bethpage/bethpage dark
  light: '#768176', // bethpage light
  subtle: '#BAC0BA', // bethpage subtle
  background: '#E9E9E9', // whistling
  highlight: '#F2F2F2', // whistling light
  contrast: '#F8F8FF', // pebble
  foreground: '#FFFFFF', // ghost
}
export const advocateTheme = createTheme(advocateColors)

export const leadprodigyColors = {
  actionStandard: '#0A7CBF', // valhalla standard
  actionHover: '#004771', // valhalla hover
  actionLight: '#B5D8EC', // valhalla light
  actionSubtle: '#E6F2F9', // valhalla subtle
  neutral: '#333333', // bethpage/bethpage dark
  light: '#666666', // bethpage light
  subtle: '#B2B2B2', // bethpage subtle
  background: '#EFF1F5', // whistling
  highlight: '#F7F8FA', // whistling light
  contrast: '#FAFAFC', // pebble
  foreground: '#FFFFFF', // ghost
}

const lpTheme = createTheme(advocateColors)
lpTheme.brand.modalBackdrop = '#000000'

export const leadprodigyTheme = lpTheme

export const consumeraffairsColors = {
  actionStandard: '#2976D1', // valhalla standard
  actionHover: '#054086', // valhalla hover
  actionLight: '#BFD6F1', // valhalla light
  actionSubtle: '#EAF1FA', // valhalla subtle
  neutral: '#333333', // bethpage
  dark: '#000000', // bethpage dark
  light: '#666666', // bethpage light
  subtle: '#B2B2B2', // bethpage subtle
  background: '#EFF1F5', // whistling
  highlight: '#F7F8FA', // whistling light
  contrast: '#FAFAFC', // pebble
  foreground: '#FFFFFF', // ghost
}

export const consumeraffairsTheme = createTheme(consumeraffairsColors)

export const adplColors = {
  actionStandard: '#CBB26A', // valhalla standard
  actionHover: '#F5CF61', // valhalla hover
  actionLight: '#EFE8D2', // valhalla light
  actionSubtle: '#FAF7F0', // valhalla subtle
  neutral: '#000000', // bethpage
  dark: '#000000', // bethpage dark
  light: '#666666', // bethpage light
  subtle: '#B3B3B3', // bethpage subtle
  background: '#E9E9E9', // whistling
  highlight: '#F2F2F2', // whistling light
  contrast: '#FAFAFA', // pebble
  foreground: '#FFFFFF', // ghost
}

export const adplTheme = createTheme(adplColors)

export const mfgColors = {
  actionStandard: '#00678B', // valhalla standard
  actionHover: '#0DCAF0', // valhalla hover
  actionLight: '#B2D1DC', // valhalla light
  actionSubtle: '#EAF1FA', // valhalla subtle
  neutral: '#414B5B', // bethpage
  dark: '#121E32', // bethpage dark
  light: '#717884', // bethpage light
  subtle: '#B8BBC1', // bethpage subtle
  background: '#EFF1F5', // whistling
  highlight: '#F7F8FA', // whistling light
  contrast: '#FAFAFC', // pebble
  foreground: '#FFFFFF', // ghost
}

export const mfgTheme = createTheme(mfgColors)

export const etelequoteColors = {
  actionStandard: '#2b388c', // valhalla standard
  actionHover: '#53a8dc', // valhalla hover
  actionLight: '#B2D1DC', // valhalla light
  actionSubtle: '#EAF1FA', // valhalla subtle
  neutral: '#414B5B', // bethpage
  dark: '#121E32', // bethpage dark
  light: '#717884', // bethpage light
  subtle: '#B8BBC1', // bethpage subtle
  background: '#EFF1F5', // whistling
  highlight: '#F7F8FA', // whistling light
  contrast: '#FAFAFC', // pebble
  foreground: '#FFFFFF', // ghost
}

export const etelequoteTheme = createTheme(etelequoteColors)
